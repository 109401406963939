import { motion } from "framer-motion";

const WagaraSvg = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 232 1034"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        d="M93.6 42.8196H138.4M93.6 42.8196L71.2 79.3114M93.6 42.8196L116 79.3114M93.6 42.8196L116 53.836M93.6 42.8196V68.295M138.4 42.8196L160.8 79.3114M138.4 42.8196V68.295M138.4 42.8196L116 53.836M138.4 42.8196L116 79.3114M160.8 79.3114L138.4 118.557M160.8 79.3114L138.4 68.295M160.8 79.3114L138.4 92.3934M160.8 79.3114H116M138.4 118.557H93.6M138.4 118.557L116 79.3114M138.4 118.557V92.3934M138.4 118.557L116 106.164M93.6 118.557L71.2 79.3114M93.6 118.557V92.3934M93.6 118.557L116 79.3114M93.6 118.557L116 106.164M71.2 79.3114L93.6 68.295M71.2 79.3114H116M71.2 79.3114L93.6 92.3934M116 79.3114V53.836M116 79.3114L93.6 68.295M116 79.3114L138.4 68.295M116 79.3114L138.4 92.3934M116 79.3114L93.6 92.3934M116 79.3114V106.164"
        stroke="#4b6587"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 0.6, ease: "easeInOut" }}
      />
      <motion.path
        d="M160.6 80.8196H205.4M160.6 80.8196L138.2 117.311M160.6 80.8196L183 117.311M160.6 80.8196L183 91.836M160.6 80.8196V106.295M205.4 80.8196L227.8 117.311M205.4 80.8196V106.295M205.4 80.8196L183 91.836M205.4 80.8196L183 117.311M227.8 117.311L205.4 156.557M227.8 117.311L205.4 106.295M227.8 117.311L205.4 130.393M227.8 117.311H183M205.4 156.557H160.6M205.4 156.557L183 117.311M205.4 156.557V130.393M205.4 156.557L183 144.164M160.6 156.557L138.2 117.311M160.6 156.557V130.393M160.6 156.557L183 117.311M160.6 156.557L183 144.164M138.2 117.311L160.6 106.295M138.2 117.311H183M138.2 117.311L160.6 130.393M183 117.311V91.836M183 117.311L160.6 106.295M183 117.311L205.4 106.295M183 117.311L205.4 130.393M183 117.311L160.6 130.393M183 117.311V144.164"
        stroke="#4b6587"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 0.8, ease: "easeInOut" }}
      />
      <motion.path
        d="M93.6 118.82H138.4M93.6 118.82L71.2 155.311M93.6 118.82L116 155.311M93.6 118.82L116 129.836M93.6 118.82V144.295M138.4 118.82L160.8 155.311M138.4 118.82V144.295M138.4 118.82L116 129.836M138.4 118.82L116 155.311M160.8 155.311L138.4 194.557M160.8 155.311L138.4 144.295M160.8 155.311L138.4 168.393M160.8 155.311H116M138.4 194.557H93.6M138.4 194.557L116 155.311M138.4 194.557V168.393M138.4 194.557L116 182.164M93.6 194.557L71.2 155.311M93.6 194.557V168.393M93.6 194.557L116 155.311M93.6 194.557L116 182.164M71.2 155.311L93.6 144.295M71.2 155.311H116M71.2 155.311L93.6 168.393M116 155.311V129.836M116 155.311L93.6 144.295M116 155.311L138.4 144.295M116 155.311L138.4 168.393M116 155.311L93.6 168.393M116 155.311V182.164"
        stroke="#4b6587"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 1.2, ease: "easeInOut" }}
      />
      <motion.path
        d="M93.6 194.82H138.4M93.6 194.82L71.2 231.311M93.6 194.82L116 231.311M93.6 194.82L116 205.836M93.6 194.82V220.295M138.4 194.82L160.8 231.311M138.4 194.82V220.295M138.4 194.82L116 205.836M138.4 194.82L116 231.311M160.8 231.311L138.4 270.557M160.8 231.311L138.4 220.295M160.8 231.311L138.4 244.393M160.8 231.311H116M138.4 270.557H93.6M138.4 270.557L116 231.311M138.4 270.557V244.393M138.4 270.557L116 258.164M93.6 270.557L71.2 231.311M93.6 270.557V244.393M93.6 270.557L116 231.311M93.6 270.557L116 258.164M71.2 231.311L93.6 220.295M71.2 231.311H116M71.2 231.311L93.6 244.393M116 231.311V205.836M116 231.311L93.6 220.295M116 231.311L138.4 220.295M116 231.311L138.4 244.393M116 231.311L93.6 244.393M116 231.311V258.164"
        stroke="#4b6587"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 1.4, ease: "easeInOut" }}
      />
      <motion.path
        d="M93.6 270.82H138.4M93.6 270.82L71.2 307.311M93.6 270.82L116 307.311M93.6 270.82L116 281.836M93.6 270.82V296.295M138.4 270.82L160.8 307.311M138.4 270.82V296.295M138.4 270.82L116 281.836M138.4 270.82L116 307.311M160.8 307.311L138.4 346.557M160.8 307.311L138.4 296.295M160.8 307.311L138.4 320.393M160.8 307.311H116M138.4 346.557H93.6M138.4 346.557L116 307.311M138.4 346.557V320.393M138.4 346.557L116 334.164M93.6 346.557L71.2 307.311M93.6 346.557V320.393M93.6 346.557L116 307.311M93.6 346.557L116 334.164M71.2 307.311L93.6 296.295M71.2 307.311H116M71.2 307.311L93.6 320.393M116 307.311V281.836M116 307.311L93.6 296.295M116 307.311L138.4 296.295M116 307.311L138.4 320.393M116 307.311L93.6 320.393M116 307.311V334.164"
        stroke="#4b6587"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 2.2, ease: "easeInOut" }}
      />
      <motion.path
        d="M93.6 346.82H138.4M93.6 346.82L71.2 383.311M93.6 346.82L116 383.311M93.6 346.82L116 357.836M93.6 346.82V372.295M138.4 346.82L160.8 383.311M138.4 346.82V372.295M138.4 346.82L116 357.836M138.4 346.82L116 383.311M160.8 383.311L138.4 422.557M160.8 383.311L138.4 372.295M160.8 383.311L138.4 396.393M160.8 383.311H116M138.4 422.557H93.6M138.4 422.557L116 383.311M138.4 422.557V396.393M138.4 422.557L116 410.164M93.6 422.557L71.2 383.311M93.6 422.557V396.393M93.6 422.557L116 383.311M93.6 422.557L116 410.164M71.2 383.311L93.6 372.295M71.2 383.311H116M71.2 383.311L93.6 396.393M116 383.311V357.836M116 383.311L93.6 372.295M116 383.311L138.4 372.295M116 383.311L138.4 396.393M116 383.311L93.6 396.393M116 383.311V410.164"
        stroke="#4b6587"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 2.4, ease: "easeInOut" }}
      />
      <motion.path
        d="M93.6 422.82H138.4M93.6 422.82L71.2 459.311M93.6 422.82L116 459.311M93.6 422.82L116 433.836M93.6 422.82V448.295M138.4 422.82L160.8 459.311M138.4 422.82V448.295M138.4 422.82L116 433.836M138.4 422.82L116 459.311M160.8 459.311L138.4 498.557M160.8 459.311L138.4 448.295M160.8 459.311L138.4 472.393M160.8 459.311H116M138.4 498.557H93.6M138.4 498.557L116 459.311M138.4 498.557V472.393M138.4 498.557L116 486.164M93.6 498.557L71.2 459.311M93.6 498.557V472.393M93.6 498.557L116 459.311M93.6 498.557L116 486.164M71.2 459.311L93.6 448.295M71.2 459.311H116M71.2 459.311L93.6 472.393M116 459.311V433.836M116 459.311L93.6 448.295M116 459.311L138.4 448.295M116 459.311L138.4 472.393M116 459.311L93.6 472.393M116 459.311V486.164"
        stroke="#4b6587"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 2.6, ease: "easeInOut" }}
      />
      <motion.path
        d="M93.6 498.82H138.4M93.6 498.82L71.2 535.311M93.6 498.82L116 535.311M93.6 498.82L116 509.836M93.6 498.82V524.295M138.4 498.82L160.8 535.311M138.4 498.82V524.295M138.4 498.82L116 509.836M138.4 498.82L116 535.311M160.8 535.311L138.4 574.557M160.8 535.311L138.4 524.295M160.8 535.311L138.4 548.393M160.8 535.311H116M138.4 574.557H93.6M138.4 574.557L116 535.311M138.4 574.557V548.393M138.4 574.557L116 562.164M93.6 574.557L71.2 535.311M93.6 574.557V548.393M93.6 574.557L116 535.311M93.6 574.557L116 562.164M71.2 535.311L93.6 524.295M71.2 535.311H116M71.2 535.311L93.6 548.393M116 535.311V509.836M116 535.311L93.6 524.295M116 535.311L138.4 524.295M116 535.311L138.4 548.393M116 535.311L93.6 548.393M116 535.311V562.164"
        stroke="#4b6587"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 3.4, ease: "easeInOut" }}
      />
      <motion.path
        d="M93.6 574.82H138.4M93.6 574.82L71.2 611.311M93.6 574.82L116 611.311M93.6 574.82L116 585.836M93.6 574.82V600.295M138.4 574.82L160.8 611.311M138.4 574.82V600.295M138.4 574.82L116 585.836M138.4 574.82L116 611.311M160.8 611.311L138.4 650.557M160.8 611.311L138.4 600.295M160.8 611.311L138.4 624.393M160.8 611.311H116M138.4 650.557H93.6M138.4 650.557L116 611.311M138.4 650.557V624.393M138.4 650.557L116 638.164M93.6 650.557L71.2 611.311M93.6 650.557V624.393M93.6 650.557L116 611.311M93.6 650.557L116 638.164M71.2 611.311L93.6 600.295M71.2 611.311H116M71.2 611.311L93.6 624.393M116 611.311V585.836M116 611.311L93.6 600.295M116 611.311L138.4 600.295M116 611.311L138.4 624.393M116 611.311L93.6 624.393M116 611.311V638.164"
        stroke="#4b6587"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 3.6, ease: "easeInOut" }}
      />
      <motion.path
        d="M93.6 650.82H138.4M93.6 650.82L71.2 687.311M93.6 650.82L116 687.311M93.6 650.82L116 661.836M93.6 650.82V676.295M138.4 650.82L160.8 687.311M138.4 650.82V676.295M138.4 650.82L116 661.836M138.4 650.82L116 687.311M160.8 687.311L138.4 726.557M160.8 687.311L138.4 676.295M160.8 687.311L138.4 700.393M160.8 687.311H116M138.4 726.557H93.6M138.4 726.557L116 687.311M138.4 726.557V700.393M138.4 726.557L116 714.164M93.6 726.557L71.2 687.311M93.6 726.557V700.393M93.6 726.557L116 687.311M93.6 726.557L116 714.164M71.2 687.311L93.6 676.295M71.2 687.311H116M71.2 687.311L93.6 700.393M116 687.311V661.836M116 687.311L93.6 676.295M116 687.311L138.4 676.295M116 687.311L138.4 700.393M116 687.311L93.6 700.393M116 687.311V714.164"
        stroke="#4b6587"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 3.6, ease: "easeInOut" }}
      />
      <motion.path
        d="M93.6 726.82H138.4M93.6 726.82L71.2 763.311M93.6 726.82L116 763.311M93.6 726.82L116 737.836M93.6 726.82V752.295M138.4 726.82L160.8 763.311M138.4 726.82V752.295M138.4 726.82L116 737.836M138.4 726.82L116 763.311M160.8 763.311L138.4 802.557M160.8 763.311L138.4 752.295M160.8 763.311L138.4 776.393M160.8 763.311H116M138.4 802.557H93.6M138.4 802.557L116 763.311M138.4 802.557V776.393M138.4 802.557L116 790.164M93.6 802.557L71.2 763.311M93.6 802.557V776.393M93.6 802.557L116 763.311M93.6 802.557L116 790.164M71.2 763.311L93.6 752.295M71.2 763.311H116M71.2 763.311L93.6 776.393M116 763.311V737.836M116 763.311L93.6 752.295M116 763.311L138.4 752.295M116 763.311L138.4 776.393M116 763.311L93.6 776.393M116 763.311V790.164"
        stroke="#4b6587"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 4, ease: "easeInOut" }}
      />
      <motion.path
        d="M93.6 802.82H138.4M93.6 802.82L71.2 839.311M93.6 802.82L116 839.311M93.6 802.82L116 813.836M93.6 802.82V828.295M138.4 802.82L160.8 839.311M138.4 802.82V828.295M138.4 802.82L116 813.836M138.4 802.82L116 839.311M160.8 839.311L138.4 878.557M160.8 839.311L138.4 828.295M160.8 839.311L138.4 852.393M160.8 839.311H116M138.4 878.557H93.6M138.4 878.557L116 839.311M138.4 878.557V852.393M138.4 878.557L116 866.164M93.6 878.557L71.2 839.311M93.6 878.557V852.393M93.6 878.557L116 839.311M93.6 878.557L116 866.164M71.2 839.311L93.6 828.295M71.2 839.311H116M71.2 839.311L93.6 852.393M116 839.311V813.836M116 839.311L93.6 828.295M116 839.311L138.4 828.295M116 839.311L138.4 852.393M116 839.311L93.6 852.393M116 839.311V866.164"
        stroke="#4b6587"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 4.2, ease: "easeInOut" }}
      />
      <motion.path
        d="M93.6 878.82H138.4M93.6 878.82L71.2 915.311M93.6 878.82L116 915.311M93.6 878.82L116 889.836M93.6 878.82V904.295M138.4 878.82L160.8 915.311M138.4 878.82V904.295M138.4 878.82L116 889.836M138.4 878.82L116 915.311M160.8 915.311L138.4 954.557M160.8 915.311L138.4 904.295M160.8 915.311L138.4 928.393M160.8 915.311H116M138.4 954.557H93.6M138.4 954.557L116 915.311M138.4 954.557V928.393M138.4 954.557L116 942.164M93.6 954.557L71.2 915.311M93.6 954.557V928.393M93.6 954.557L116 915.311M93.6 954.557L116 942.164M71.2 915.311L93.6 904.295M71.2 915.311H116M71.2 915.311L93.6 928.393M116 915.311V889.836M116 915.311L93.6 904.295M116 915.311L138.4 904.295M116 915.311L138.4 928.393M116 915.311L93.6 928.393M116 915.311V942.164"
        stroke="#4b6587"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 4.4, ease: "easeInOut" }}
      />
      <motion.path
        d="M93.6 954.82H138.4M93.6 954.82L71.2 991.311M93.6 954.82L116 991.311M93.6 954.82L116 965.836M93.6 954.82V980.295M138.4 954.82L160.8 991.311M138.4 954.82V980.295M138.4 954.82L116 965.836M138.4 954.82L116 991.311M160.8 991.311L138.4 1030.56M160.8 991.311L138.4 980.295M160.8 991.311L138.4 1004.39M160.8 991.311H116M138.4 1030.56H93.6M138.4 1030.56L116 991.311M138.4 1030.56V1004.39M138.4 1030.56L116 1018.16M93.6 1030.56L71.2 991.311M93.6 1030.56V1004.39M93.6 1030.56L116 991.311M93.6 1030.56L116 1018.16M71.2 991.311L93.6 980.295M71.2 991.311H116M71.2 991.311L93.6 1004.39M116 991.311V965.836M116 991.311L93.6 980.295M116 991.311L138.4 980.295M116 991.311L138.4 1004.39M116 991.311L93.6 1004.39M116 991.311V1018.16"
        stroke="#4b6587"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 4.6, ease: "easeInOut" }}
      />
      <motion.path
        d="M160.6 459.82H205.4M160.6 459.82L138.2 496.311M160.6 459.82L183 496.311M160.6 459.82L183 470.836M160.6 459.82V485.295M205.4 459.82L227.8 496.311M205.4 459.82V485.295M205.4 459.82L183 470.836M205.4 459.82L183 496.311M227.8 496.311L205.4 535.557M227.8 496.311L205.4 485.295M227.8 496.311L205.4 509.393M227.8 496.311H183M205.4 535.557H160.6M205.4 535.557L183 496.311M205.4 535.557V509.393M205.4 535.557L183 523.164M160.6 535.557L138.2 496.311M160.6 535.557V509.393M160.6 535.557L183 496.311M160.6 535.557L183 523.164M138.2 496.311L160.6 485.295M138.2 496.311H183M138.2 496.311L160.6 509.393M183 496.311V470.836M183 496.311L160.6 485.295M183 496.311L205.4 485.295M183 496.311L205.4 509.393M183 496.311L160.6 509.393M183 496.311V523.164"
        stroke="#4b6587"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 3.3, ease: "easeInOut" }}
      />
      <motion.path
        d="M26.6 916.82H71.4M26.6 916.82L4.19995 953.311M26.6 916.82L49 953.311M26.6 916.82L49 927.836M26.6 916.82V942.295M71.4 916.82L93.7999 953.311M71.4 916.82V942.295M71.4 916.82L49 927.836M71.4 916.82L49 953.311M93.7999 953.311L71.4 992.557M93.7999 953.311L71.4 942.295M93.7999 953.311L71.4 966.393M93.7999 953.311H49M71.4 992.557H26.6M71.4 992.557L49 953.311M71.4 992.557V966.393M71.4 992.557L49 980.164M26.6 992.557L4.19995 953.311M26.6 992.557V966.393M26.6 992.557L49 953.311M26.6 992.557L49 980.164M4.19995 953.311L26.6 942.295M4.19995 953.311H49M4.19995 953.311L26.6 966.393M49 953.311V927.836M49 953.311L26.6 942.295M49 953.311L71.4 942.295M49 953.311L71.4 966.393M49 953.311L26.6 966.393M49 953.311V980.164"
        stroke="#4b6587"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 4.8, ease: "easeInOut" }}
      />
      <motion.path
        d="M160.6 917.82H205.4M160.6 917.82L138.2 954.311M160.6 917.82L183 954.311M160.6 917.82L183 928.836M160.6 917.82V943.295M205.4 917.82L227.8 954.311M205.4 917.82V943.295M205.4 917.82L183 928.836M205.4 917.82L183 954.311M227.8 954.311L205.4 993.557M227.8 954.311L205.4 943.295M227.8 954.311L205.4 967.393M227.8 954.311H183M205.4 993.557H160.6M205.4 993.557L183 954.311M205.4 993.557V967.393M205.4 993.557L183 981.164M160.6 993.557L138.2 954.311M160.6 993.557V967.393M160.6 993.557L183 954.311M160.6 993.557L183 981.164M138.2 954.311L160.6 943.295M138.2 954.311H183M138.2 954.311L160.6 967.393M183 954.311V928.836M183 954.311L160.6 943.295M183 954.311L205.4 943.295M183 954.311L205.4 967.393M183 954.311L160.6 967.393M183 954.311V981.164"
        stroke="#4b6587"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 4.8, ease: "easeInOut" }}
      />
      <motion.path
        d="M26.6 536.82H71.4M26.6 536.82L4.19995 573.311M26.6 536.82L49 573.311M26.6 536.82L49 547.836M26.6 536.82V562.295M71.4 536.82L93.7999 573.311M71.4 536.82V562.295M71.4 536.82L49 547.836M71.4 536.82L49 573.311M93.7999 573.311L71.4 612.557M93.7999 573.311L71.4 562.295M93.7999 573.311L71.4 586.393M93.7999 573.311H49M71.4 612.557H26.6M71.4 612.557L49 573.311M71.4 612.557V586.393M71.4 612.557L49 600.164M26.6 612.557L4.19995 573.311M26.6 612.557V586.393M26.6 612.557L49 573.311M26.6 612.557L49 600.164M4.19995 573.311L26.6 562.295M4.19995 573.311H49M4.19995 573.311L26.6 586.393M49 573.311V547.836M49 573.311L26.6 562.295M49 573.311L71.4 562.295M49 573.311L71.4 586.393M49 573.311L26.6 586.393M49 573.311V600.164"
        stroke="#4b6587"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 3.6, ease: "easeInOut" }}
      />
      <motion.path
        d="M160.6 383.82H205.4M160.6 383.82L138.2 420.311M160.6 383.82L183 420.311M160.6 383.82L183 394.836M160.6 383.82V409.295M205.4 383.82L227.8 420.311M205.4 383.82V409.295M205.4 383.82L183 394.836M205.4 383.82L183 420.311M227.8 420.311L205.4 459.557M227.8 420.311L205.4 409.295M227.8 420.311L205.4 433.393M227.8 420.311H183M205.4 459.557H160.6M205.4 459.557L183 420.311M205.4 459.557V433.393M205.4 459.557L183 447.164M160.6 459.557L138.2 420.311M160.6 459.557V433.393M160.6 459.557L183 420.311M160.6 459.557L183 447.164M138.2 420.311L160.6 409.295M138.2 420.311H183M138.2 420.311L160.6 433.393M183 420.311V394.836M183 420.311L160.6 409.295M183 420.311L205.4 409.295M183 420.311L205.4 433.393M183 420.311L160.6 433.393M183 420.311V447.164"
        stroke="#4b6587"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 2.8, ease: "easeInOut" }}
      />
      <motion.path
        d="M26.6 4.81964H71.4M26.6 4.81964L4.19995 41.3114M26.6 4.81964L49 41.3114M26.6 4.81964L49 15.836M26.6 4.81964V30.295M71.4 4.81964L93.7999 41.3114M71.4 4.81964V30.295M71.4 4.81964L49 15.836M71.4 4.81964L49 41.3114M93.7999 41.3114L71.4 80.5573M93.7999 41.3114L71.4 30.295M93.7999 41.3114L71.4 54.3934M93.7999 41.3114H49M71.4 80.5573H26.6M71.4 80.5573L49 41.3114M71.4 80.5573V54.3934M71.4 80.5573L49 68.1639M26.6 80.5573L4.19995 41.3114M26.6 80.5573V54.3934M26.6 80.5573L49 41.3114M26.6 80.5573L49 68.1639M4.19995 41.3114L26.6 30.295M4.19995 41.3114H49M4.19995 41.3114L26.6 54.3934M49 41.3114V15.836M49 41.3114L26.6 30.295M49 41.3114L71.4 30.295M49 41.3114L71.4 54.3934M49 41.3114L26.6 54.3934M49 41.3114V68.1639"
        stroke="#4b6587"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 0.2, ease: "easeInOut" }}
      />
      <motion.path
        d="M26.6 233.82H71.4M26.6 233.82L4.19995 270.311M26.6 233.82L49 270.311M26.6 233.82L49 244.836M26.6 233.82V259.295M71.4 233.82L93.7999 270.311M71.4 233.82V259.295M71.4 233.82L49 244.836M71.4 233.82L49 270.311M93.7999 270.311L71.4 309.557M93.7999 270.311L71.4 259.295M93.7999 270.311L71.4 283.393M93.7999 270.311H49M71.4 309.557H26.6M71.4 309.557L49 270.311M71.4 309.557V283.393M71.4 309.557L49 297.164M26.6 309.557L4.19995 270.311M26.6 309.557V283.393M26.6 309.557L49 270.311M26.6 309.557L49 297.164M4.19995 270.311L26.6 259.295M4.19995 270.311H49M4.19995 270.311L26.6 283.393M49 270.311V244.836M49 270.311L26.6 259.295M49 270.311L71.4 259.295M49 270.311L71.4 283.393M49 270.311L26.6 283.393M49 270.311V297.164"
        stroke="#4b6587"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 1.6, ease: "easeInOut" }}
      />
      <motion.path
        d="M26.6 765.82H71.4M26.6 765.82L4.19995 802.311M26.6 765.82L49 802.311M26.6 765.82L49 776.836M26.6 765.82V791.295M71.4 765.82L93.7999 802.311M71.4 765.82V791.295M71.4 765.82L49 776.836M71.4 765.82L49 802.311M93.7999 802.311L71.4 841.557M93.7999 802.311L71.4 791.295M93.7999 802.311L71.4 815.393M93.7999 802.311H49M71.4 841.557H26.6M71.4 841.557L49 802.311M71.4 841.557V815.393M71.4 841.557L49 829.164M26.6 841.557L4.19995 802.311M26.6 841.557V815.393M26.6 841.557L49 802.311M26.6 841.557L49 829.164M4.19995 802.311L26.6 791.295M4.19995 802.311H49M4.19995 802.311L26.6 815.393M49 802.311V776.836M49 802.311L26.6 791.295M49 802.311L71.4 791.295M49 802.311L71.4 815.393M49 802.311L26.6 815.393M49 802.311V829.164"
        stroke="#4b6587"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 4, ease: "easeInOut" }}
      />
    </svg>
  );
};

export default WagaraSvg;
